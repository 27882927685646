document.querySelectorAll('.navbar-toggler').forEach(function (el) {
  el.addEventListener('click', function () {
    const nav = el.parentElement
    const _class = 'open'

    nav.classList.contains(_class)
      ? nav.classList.remove(_class)
      : nav.classList.add(_class)
  })
})
document.getElementById('langSwitchBtn').addEventListener('click', function (e) {
  e.preventDefault()
})
